import React, { useState } from 'react';
import { FaUserTie } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { IoMdLock } from 'react-icons/io';
import './Auth.scss';
import { BsFillTelephoneFill } from 'react-icons/bs';
import * as Yup from "yup";
import { Form, Formik } from 'formik';
import { InputText } from '../../components/Common/InputText';
import { Button } from '../../components/Common/Button';
import { Regex } from '../../utils/Regex';
import { RegisterManuallyAction } from '../../redux/Slices/AuthSlice';
import { useNavigate } from 'react-router-dom';
import SocialLoginButton from './SocialLoginButton';

function Register() {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
    }
    return (

        <>
            <section className='login_register_section'>
                <div className='left_side_content_filed'>
                    <div className='login_input_filed'>
                        <div className='logo_images'>
                            <img src="/images/glide_scale_logo.svg" alt="" />
                        </div>
                        <div className='text_login_description'>
                            <h1>Welcome to Glidescale</h1>
                            <p>Kindly choose what you prefer</p>
                        </div>
                        <Formik
                            initialValues={{
                                user_name: '',
                                email: '',
                                phone: '',
                                password: '',
                                confirm_password: '',
                            }}
                            validationSchema={Yup.object().shape({
                                user_name: Yup.string().required("User Name is Required!"),
                                email: Yup.string().email("Invalid email address").required("Email is Required!").matches(Regex?.email, "Enter valid email address!"),
                                // phone: Yup.string().required("Phone Number is Required!"),
                                phone: Yup.string().min(9, "Phone number must be at least 9 number").max(9, "Phone number must be at least 9 number").matches(Regex.MOBILE_NUMBER_REGEX, 'Phone number is not valid').required('Phone Number is Required!'),
                                password: Yup.string().required("Password is Required")
                                                    .min(6, "Password should be between 6 to 16 characters including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.")
                                                    .max(16, "Password should be between 6 to 16 characters including 1 uppercase, 1 lowercase, 1 special character, and 1 digit.")
                                                    .matches(Regex.password, "Password should be between 6 to 16 characters including 1 uppercase, 1 lowercase, 1 special character, and 1 digit."),
                                // password: Yup.string().required("Password is Required!"),
                                confirm_password: Yup.string().required("Confirm Password is Required").oneOf([Yup.ref('password'), null], "Confirm Password not matched!"),
                            })}
                            onSubmit={(value) => {
                                const payload = {
                                    user_name: value?.user_name,
                                    email: value?.email,
                                    phone: value?.phone,
                                    password: value?.password
                                }
                                setLoader(true)
                                RegisterManuallyAction(payload, (response) => {
                                    if (response?.status === true) {
                                        setLoader(false)
                                        navigate("/otp", { replace: true, state: { email: value?.email, phone: value?.phone } })
                                    } else {
                                        setLoader(false)
                                    }
                                })();
                            }}
                        >
                            {(formik) => {
                                return (
                                    <Form onChange={handleChange}>
                                        <div className='input_filed_login_register'>
                                            <InputText
                                                name="user_name"
                                                type="text"
                                                placeholder='Username*'
                                                value={formik.values.user_name}
                                                FieldIcon={<FaUserTie />}
                                                className="common_input_filed"
                                            />

                                            <InputText
                                                name="email"
                                                type="email"
                                                placeholder='Email*'
                                                value={formik.values.email}
                                                FieldIcon={<MdEmail />}
                                                className="common_input_filed"
                                            />

                                            <InputText
                                                name="phone"
                                                type="number"
                                                placeholder='Phone Number*'
                                                value={formik.values.phone}
                                                FieldIcon={<BsFillTelephoneFill />}
                                                className="common_input_filed"
                                            />

                                            <InputText
                                                name="password"
                                                type="password"
                                                placeholder='Password'
                                                value={formik.values.password}
                                                FieldIcon={<IoMdLock />}
                                                className="common_input_filed"
                                            />

                                            <InputText
                                                name="confirm_password"
                                                type="password"
                                                placeholder='Confirm Password'
                                                value={formik.values.confirm_password}
                                                FieldIcon={<IoMdLock />}
                                                className="common_input_filed"
                                            />
                                        </div>
                                        <div className='sign_in_btn'>
                                            <Button
                                                className="sign-in"
                                                text='Sign up'
                                                type="submit"
                                                loader={loader}
                                                disabled={!(formik.isValid && formik.dirty) || loader}
                                            />
                                            <SocialLoginButton btnText="Sign-up" />
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </div>
                </div>

                <div className='right_side_content'>
                    <div className='right_login_description'>
                        <div className='img_right_side'>
                            <img src="/images/login_right_side.svg" alt="" />
                        </div>
                        <div className='content_right_side'>
                            <h2>Your Security First, Efficiency Paramount.</h2>
                            <p> Glidescale employs an array of defensive measures to ensure you and your marketing is as safe and reliable as possible. Freely create your own or join a PPC Affiliate Program without the hassle or worry.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Register