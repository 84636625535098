import React from 'react';
import '../../../pages/Style.scss';
import { useLocation } from 'react-router-dom';
import { BsDownload } from "react-icons/bs";

function UsersManagementListView() {
    const { state } = useLocation();
    const SUPPORTED_FILE_FORMAT = ["jpeg", "png", "jpg"]
    return (
        <>
            <div className='top_heading_button'>
                <h1 className='main_heading_top'> User: {state.user_name}</h1>
            </div>

            <div className='view_page_management'>

                <div className='profile_view_page'>
                    <div className='profile_img_table'>
                        <img
                            className='common_design_img'
                            src={state.profile ? state.profile : "/images/dummy-image.jpg"}
                            alt={state.user_name}
                        />
                    </div>

                    <div className='view_page_content_section'>
                        <div className='heading_description'>
                            <h1>User Name</h1>
                            <p> {state.user_name ? state.user_name : "-"} </p>
                        </div>
                        <div className='heading_description'>
                            <h1>Full Name</h1>
                            <p> {state.full_name ? state.full_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1>Phone Number</h1>
                            <p> {state.phone ? state.phone : "-"} </p>
                        </div>
                        <div className='heading_description'>
                            <h1>Email</h1>
                            <p> {state.email ? state.email : "-"} </p>
                        </div>
                        <div className='heading_description'>
                            <h1>Status</h1>
                            <p> {state?.status == 1 ? "Active" : "Inactive"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1>Company Name </h1>
                            <p> {state.company_name ? state.company_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Certificate  </h1>
                            <div className='d-flex align-content-center justify-content-between w-100'>
                                {state.certificate ?
                                    SUPPORTED_FILE_FORMAT.includes(state.certificate?.split('.').pop('')) ?
                                        <img src={state.certificate} alt={state.user_name} />
                                        :
                                        state.certificate?.split('.').pop('') === "pdf" ?
                                            <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/PDF_file_icon.svg" alt="PDF" />
                                            :
                                            state.certificate?.split('.').pop('') === "doc" || state.certificate?.split('.').pop('') === "docx" ?
                                                <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/word_icon.svg" alt="DOC" />
                                                :
                                                state.certificate?.split('.').pop('') === "ppt" ?
                                                    <img className="img-fluid" referrerPolicy="no-referrer" src="/images/file/microsoft_icon.svg" alt="PPT" />
                                                    :
                                                    "-"
                                    :
                                    "-"
                                }

                                {state.certificate ? <a href={state.certificate} target='_blank'> <BsDownload /> </a> : null}
                            </div>
                        </div>

                        <div className='heading_description'>
                            <h1> Total Experience </h1>
                            <p> {state.experience ? state.experience : "-"} </p>
                        </div>

                        {/* <div className='heading_description'>
                            <h1> Bank Name </h1>
                            <p> {state.bank_name ? state.bank_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Account Holder Name </h1>
                            <p> {state.bank_holder_name ? state.bank_holder_name : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Account Number </h1>
                            <p> {state.account_number ? state.account_number : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Branch Code </h1>
                            <p> {state.branch_code ? state.branch_code : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> Account Type </h1>
                            <p> {state.account_type ? state.account_type : "-"} </p>
                        </div>

                        <div className='heading_description'>
                            <h1> CPF Number </h1>
                            <p> {state.cpf_number ? state.cpf_number : "-"} </p>
                        </div> */}
                    </div>
                </div>

            </div>

        </>
    )
}

export default UsersManagementListView;