import { useState, React, useEffect } from 'react';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import { OverlayTrigger, Tabs, Tooltip } from 'react-bootstrap';
import { Tab } from 'bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCopy } from "react-icons/fa6";
import { MdDeleteForever, MdOutlineRemoveRedEye } from 'react-icons/md';
import CampaignsModal from '../../components/Modal/CampaignsModal';
import DeleteCampaigns from '../../components/Modal/DeleteCampaigns';
import CompleteProfile from '../Auth/CompleteProfile';
import '../../pages/Style.scss';
import { CommonModal } from '../../components/Modal/CommonModal';
import  ManualInviteEmailModal  from '../../components/Modal/ManualInviteEmailModal';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteCampaignsAction, FetchCampaignListAction, FetchJoinedCampaignListAction, FetchMyCampaignListAction, FetchRequestCampaignListAction, JoinedCampaignAction, MyCampaignStatusUpdateAction, RequestCampaignStatusAction } from '../../redux/Slices/CampaignSlice';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { CopyLinkToClipboard, HasConsecutiveSpaces, debounceSearch } from '../../utils/Function';
import CampaignsInviteLinkModel from './CampaignsInviteLinkModel';
import { FiEdit } from "react-icons/fi";
import { Pagination } from '../../components/Hooks/Pagination';
import {Helmet} from "react-helmet-async"
function Campaigns() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [CampaignsShow, setCampaignsShow] = useState(false);
    const [CampaignsManulInviteMode, setCampaignsManulInviteMode] = useState({});
    const [ManualInviteShow, setManualInviteShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [CampaignsEditMode, setCampaignsEditMode] = useState({});
    const [DeleteCampaignsShow, setDeleteCampaignsShow] = useState(false);
    const [DeleteCampaignsID, setDeleteCampaignsID] = useState();
    const [ActiveInactiveCampaignShow, setActiveInactiveCampaignShow] = useState(false);
    const [ActiveInactiveCampaignId, setActiveInactiveCampaignId] = useState();
    const [inviteLinkOptionData, setInviteLinkOptionData] = useState(false);
    const [CompleteProfileShow, setCompleteProfileShow] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    const [JoinCampaignsModalShow, setJoinCampaignsModalShow] = useState(false)
    const [JoinCampaignID, setJoinCampaignID] = useState();
    const [searchString, setSearchString] = useState("")
    const [sortFilter, setSortFilter] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(24);

    const { profile } = useSelector((state) => state?.auth)
    const { capmaignList, myCapmaignList, joinedCapmaignList, requestCapmaignList } = useSelector((state) => state?.campaign)
    const campaignOgUrl = "https://plus.unsplash.com/premium_photo-1676321688594-7c2e60a70de1?q=80&w=1924&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

    useEffect(() => {
        let data = {
            sortBy: sortFilter,
            search: searchString,
            perPage: perPageCount,
            page: currentPage,
        }
        if (selectedTab == 1) {
            dispatch(FetchCampaignListAction(data))
        }
        if (selectedTab == 2) {
            dispatch(FetchMyCampaignListAction(data))
        }
        if (selectedTab == 3) {
            dispatch(FetchJoinedCampaignListAction())
        }
        if (selectedTab == 4) {
            dispatch(FetchRequestCampaignListAction())
        }
    }, [dispatch, selectedTab, searchString, sortFilter, perPageCount, currentPage])

    const DeleteCampaings = () => {
        setLoader(true)
        DeleteCampaignsAction({ _id: DeleteCampaignsID }, (response) => {
            if (response?.status === true) {
                setLoader(false)
                setDeleteCampaignsShow(false)
                dispatch(FetchMyCampaignListAction())
            } else {
                setLoader(false)
            }
        })();
    }

    const handleSelectedTab = (key) => {
        setSearchString("")
        setSortFilter("")
        setSelectedTab(key)
    }

    const JoinedCampaing = () => {
        setLoader(true)
        JoinedCampaignAction({ campaign_id: JoinCampaignID }, (response) => {
            if (response?.status === true) {
                setLoader(false)
                setDeleteCampaignsID("")
                setJoinCampaignsModalShow(false)
                dispatch(FetchCampaignListAction())
            } else {
                setLoader(false)
            }
        })();
    }

    const handleStatusUpdate = () => {
        setLoader(true)
        MyCampaignStatusUpdateAction({ _id: ActiveInactiveCampaignId.id, status: ActiveInactiveCampaignId.status == 1 ? 5 : 1 }, (response) => {
            if (response?.status === true) {
                setLoader(false)
                setActiveInactiveCampaignShow(false)
                dispatch(FetchMyCampaignListAction())
            } else {
                setLoader(false)
            }
        })();
    }

    const handleSearchFilter = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    const handleRequestStatusUpdate = (id, status) => {
        RequestCampaignStatusAction({ _id: id, status }, (response) => {
            if (response?.status === true) {
                dispatch(FetchRequestCampaignListAction())
                setLoader(false)
            } else {
                setLoader(false)
            }
        })();
    }

    return (
        <>
            
            <div className='top_heading_button'>
                <h1 className='main_heading_top'>
                    {selectedTab == 1 ? "Browse Campaigns" : selectedTab == 2 ? "My Campaigns" : selectedTab == 3 ? "Joined Campaigns" : "Request Campaigns"}
                </h1>
                <button type='button' className='btn_blue' onClick={() => {
                    if (profile?.complete_profile === 0) {
                        setCompleteProfileShow(true)
                    } else {
                        setCampaignsShow(true)
                    }
                }}><AiOutlinePlus /> New Campaign </button>
            </div>

            <div className='tab_section_card'>
                <div className='common_tab_section'>
                    <Tabs defaultActiveKey={selectedTab} id="uncontrolled-tab-example" onSelect={handleSelectedTab}>
                        <Tab eventKey={1} title="Browse Campaigns">
                            <div className='search_box_dropdown'>
                                <div className='search_input_profile'>
                                    <div className="headerSearchBx">
                                        <input
                                            placeholder="Find Browse Campaigns"
                                            aria-label="Search"
                                            type="search"
                                            className="common_input_filed"
                                            onChange={handleSearchFilter}
                                        />
                                        <div className="search_header_icon"><AiOutlineSearch /></div>
                                    </div>
                                </div>

                                <div className='filter_select_number'>
                                    <select className='common_input_filed' name='sortBy' onChange={(e) => setSortFilter(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="dateAsc"> Ascending </option>
                                        <option value="dateDesc"> Descending </option>
                                        <option value="priceLowToHigh"> Price low to high </option>
                                        <option value="priceHighToLow"> Price high to low</option>
                                    </select>
                                </div>

                            </div>
                            <div className='row g-5'>
                                {capmaignList?.bowse_cam_list && capmaignList?.bowse_cam_list?.length > 0 ?
                                    capmaignList?.bowse_cam_list?.map((value, index) => (
                                        <div className='col-md-12 col-lg-6' key={index}>
                                            <div className='card_campaigns_main campaigns_list_card'>
                                                <div className="details-box">
                                                    <div className="price_heading_box">
                                                        <div className='details-box-first2'>
                                                            <button
                                                                className="btn_blue"
                                                                type='button'
                                                                onClick={() => navigate(`/browse-campaigns/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`, { state: { mode: "browse-campaigns" } })}
                                                            >
                                                                <div className='heading_campaings'> {value?.campaign_name} </div> <div className='share_next_page'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                    <g id="Group_50385" data-name="Group 50385" transform="translate(-1512.25 -556.75)">
                                                                        <path id="Path_135412" data-name="Path 135412" d="M8.636,3.5a.5.5,0,0,0-.5-.5H1.5A1.5,1.5,0,0,0,0,4.5v10A1.5,1.5,0,0,0,1.5,16h10A1.5,1.5,0,0,0,13,14.5V7.864a.5.5,0,0,0-1,0V14.5a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,1.5,4H8.136A.5.5,0,0,0,8.636,3.5Z" transform="translate(1512.5 556.5)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                        <path id="Path_135413" data-name="Path 135413" d="M16,.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0,0,1h3.793L6.146,9.146a.5.5,0,0,0,.708.708L15,1.707V5.5a.5.5,0,0,0,1,0Z" transform="translate(1512 557)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                    </g>
                                                                </svg>
                                                                </div> </button>
                                                            <h1> <div className='price_icon'>$</div> <div className='price_number_text'><span>{value?.no_of_credits ? value?.no_of_credits : 0} </span> <span>Remaining Credit</span></div> </h1>
                                                        </div>
                                                        <div className="price_campaigns">
                                                            <h1><div className='price_icon'>$</div> <div className='price_number_text'> <span>{value?.ppc_rate} </span> <span>PPC</span></div>  </h1>
                                                            <div className='join_product_type_row campaigns_list'>
                                                                <button type='button' className='btn_blue heading_explor'
                                                                    disabled={value?.joined == 1 || value?.joined == 3}
                                                                    onClick={() => {
                                                                        setJoinCampaignsModalShow(true)
                                                                        setJoinCampaignID(value?._id)
                                                                    }}
                                                                >
                                                                    {value?.joined == 0 ? "Join"
                                                                        : value?.joined == 1 ? "Joined"
                                                                            : value?.joined == 2 ? "Request to join"
                                                                                : value?.joined == 3 ? "Pending"
                                                                                    : ""
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="details-box-first">
                                                        <div className="campaings_detail">
                                                            <h2> Product Types </h2>
                                                            <p> {value?.product_type?.type_name ? value?.product_type?.type_name : "-"} </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Tags </h2>
                                                            <p className='tags_line'>
                                                                {value?.tags && value?.tags?.length > 0 ?
                                                                    JSON.parse(value?.tags)?.map((data, index) => (
                                                                        index < 6 ?
                                                                            <span> {data} </span>
                                                                            :
                                                                            index == 6 ? <span> +{JSON.parse(value?.tags)?.length - 6} </span>
                                                                                :
                                                                                null
                                                                    ))
                                                                    : ""
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Target Link </h2>
                                                            <p>
                                                                <a href={value?.target_link} className='text-ellipsis' target='_blank'> {value?.target_link} </a>
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2>Description</h2>
                                                            <p className='card_description_text'>{value?.description?.length >= 90 ? `${value?.description}...` : value?.description}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                }

                                <div className='col-12'>
                                    <div className='pagination_entries_section pt-0 px-0'>
                                        <div className='entries_text'>
                                            Showing {(currentPage - 1) * Number(capmaignList?.pagination?.perPage) + 1} of {capmaignList?.pagination?.totalItems} entries
                                        </div>
                                        {capmaignList?.bowse_cam_list?.length > 0 ?
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={currentPage}
                                                totalCount={capmaignList?.pagination?.totalItems}
                                                pageSize={capmaignList?.pagination?.perPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="My Campaigns">
                            <div className='search_box_dropdown'>
                                <div className='search_input_profile'>
                                    <div className="headerSearchBx">
                                        <input
                                            placeholder="Find Browse Campaigns"
                                            aria-label="Search"
                                            type="search"
                                            className="common_input_filed"
                                            onChange={handleSearchFilter}
                                        />
                                        <div className="search_header_icon"><AiOutlineSearch /></div>
                                    </div>
                                </div>

                                <div className='filter_select_number'>
                                    <select className='common_input_filed' name='sortBy' onChange={(e) => setSortFilter(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="dateAsc"> Ascending </option>
                                        <option value="dateDesc"> Descending </option>
                                        <option value="priceLowToHigh"> Price low to high </option>
                                        <option value="priceHighToLow"> Price high to low</option>
                                    </select>
                                </div>

                            </div>

                            <div className='row g-5'>
                                {myCapmaignList && myCapmaignList.length > 0 ?
                                    myCapmaignList?.map((value, index) => (
                                        <div className='col-md-12 col-lg-6' key={index}>
                                            <div className='card_campaigns_main campaigns_list_card'>
                                                <div className="details-box">
                                                    <div className="price_heading_box">
                                                        <div className='details-box-first2'>
                                                            <button
                                                                className="btn_blue"
                                                                type='button'
                                                                onClick={() => navigate(`/my-campaigns/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`, { state: { mode: "my-campaigns" } })}
                                                            >
                                                                <div className='heading_campaings'> {value?.campaign_name} </div> <div className='share_next_page'> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                    <g id="Group_50385" data-name="Group 50385" transform="translate(-1512.25 -556.75)">
                                                                        <path id="Path_135412" data-name="Path 135412" d="M8.636,3.5a.5.5,0,0,0-.5-.5H1.5A1.5,1.5,0,0,0,0,4.5v10A1.5,1.5,0,0,0,1.5,16h10A1.5,1.5,0,0,0,13,14.5V7.864a.5.5,0,0,0-1,0V14.5a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,1.5,4H8.136A.5.5,0,0,0,8.636,3.5Z" transform="translate(1512.5 556.5)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                        <path id="Path_135413" data-name="Path 135413" d="M16,.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0,0,1h3.793L6.146,9.146a.5.5,0,0,0,.708.708L15,1.707V5.5a.5.5,0,0,0,1,0Z" transform="translate(1512 557)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                    </g>
                                                                </svg>
                                                                </div> </button>
                                                            <h1> <div className='price_icon'>$</div> <div className='price_number_text'><span>{value?.no_of_credits ? value?.no_of_credits : 0} </span> <span>Remaining Credit</span></div> </h1>
                                                        </div>
                                                        <div className="price_campaigns">
                                                            <h1> <div className='price_icon'>$</div><div className='price_number_text'> <span>{value?.ppc_rate} </span> <span>PPC</span></div> </h1>
                                                            <div className='join_product_type_row campaigns_list'>

                                                            {value.advanced_option == 4 ? (
                                                            <div>
                                                                <button type='button' className='btn_blue' onClick={() => {
                                                                    setManualInviteShow(true)
                                                                    setCampaignsManulInviteMode(value)
                                                                    }}> Invite 
                                                                </button>
                                                                </div>
                                                             ) : null} 

                                                                {/* <OverlayTrigger
                                                                    placement="top"
                                                                    // delay={{ show: 250, hide: 400 }}
                                                                    overlay={(props) => (
                                                                        <Tooltip
                                                                            id="button-tooltip"
                                                                            {...props}
                                                                            style={{
                                                                                fontSize: 14,
                                                                                ...props.style,
                                                                            }}
                                                                        >
                                                                            {value?.status == 1 ?
                                                                                "You can use your credit balance." :
                                                                                "Credit balance will be freeze."
                                                                            }
                                                                        </Tooltip>
                                                                    )}
                                                                > */}
                                                                    <button
                                                                        type='button'
                                                                        className={`btn_blue ${value?.status == 1 ? "active" : "reject"}`}
                                                                        onClick={() => {
                                                                            //handleStatusUpdate(value._id, value.status)
                                                                            setActiveInactiveCampaignShow(true)
                                                                            setActiveInactiveCampaignId({id:value?._id,status:value.status})
                                                                        }}     
                                                                    >
                                                                        {value?.status == 1 ? "Active" : "Inactive"}
                                                                        
                                                                    </button>
                                                                {/* </OverlayTrigger> */}

                                                                <button type='button' className='btn_blue edit_delete' onClick={() => {
                                                                    setCampaignsShow(true)
                                                                    setCampaignsEditMode(value)
                                                                }}><FiEdit /></button>

                                                                <button type='button' className='btn_blue edit_delete delete' onClick={() => {
                                                                    setDeleteCampaignsShow(true)
                                                                    setDeleteCampaignsID(value?._id)
                                                                }}><MdDeleteForever /></button>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="details-box-first">
                                                        <div className="campaings_detail">
                                                            <h2> Product Types </h2>
                                                            <p> {value?.product_type?.type_name ? value?.product_type?.type_name : "-"} </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Tags </h2>
                                                            <p className='tags_line'>
                                                                {value?.tags && value?.tags?.length > 0 ?
                                                                    JSON.parse(value?.tags)?.map((data, index) => (
                                                                        index < 6 ?
                                                                            <span> {data} </span>
                                                                            :
                                                                            index == 6 ? <span> +{JSON.parse(value?.tags)?.length - 6} </span>
                                                                                :
                                                                                null
                                                                    ))
                                                                    : ""
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Target Link </h2>
                                                            <p>
                                                                <a href={value?.target_link} className='text-ellipsis' target='_blank'> {value?.target_link} </a>
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2>Description</h2>
                                                            <p className='card_description_text'>{value?.description?.length >= 90 ? `${value?.description}...` : value?.description}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                }
                            </div>

                            {/* <div className='content_campaigns_user'>
                                {myCapmaignList && myCapmaignList.length > 0 ?
                                    myCapmaignList?.map((value, index) => (
                                        <div className='card_box_Campaigns' key={index}>
                                            <div className='campaigns_list'>
                                                <div>
                                                    <div className='campaigns_name'>{value?.campaign_name}</div>
                                                    <div className='price_product'>
                                                        <div className='price_products'>
                                                            <BiDollar /> <span>{value?.ppc_rate} Credits</span>
                                                        </div>
                                                        <div className='price_products'>
                                                            <MdProductionQuantityLimits /> <span>{value?.product_type?.type_name ? value?.product_type?.type_name : "-"}</span>
                                                        </div>
                                                    </div>
                                                    <p> {value?.description?.length >= 90 ? `${value?.description}...` : value?.description} </p>
                                                </div>
                                                <div className='my_campaigns_right_button'>


                                                    <button type='button' className='btn_blue manage' onClick={() => {
                                                        setCampaignsShow(true)
                                                        setCampaignsEditMode(value)
                                                    }}>Manage</button>

                                                    <div className='three_dots_btn'>
                                                        <div className="drop-box drop_card_common">
                                                            <Dropdown drop={"start"}>
                                                                <Dropdown.Toggle className='btn_three_dots' variant="success" id={"dropdown-basic"}>
                                                                    <HiOutlineDotsVertical />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item as={Link} className="nav-link" onClick={() => {
                                                                        setDeleteCampaignsShow(true)
                                                                        setDeleteCampaignsID(value?._id)
                                                                    }}> Delete</Dropdown.Item>
                                                                    <Dropdown.Item
                                                                        className="nav-link"
                                                                        onClick={() => navigate(`/my-campaigns/${value?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?._id}`, { state: { mode: "my-campaigns" } })}
                                                                    >
                                                                        View
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                }
                            </div> */}
                        </Tab>

                        <Tab eventKey={3} title="Joined Campaigns">
                            <div className='row g-5'>
                                {joinedCapmaignList && joinedCapmaignList.length > 0 ?
                                    joinedCapmaignList?.map((value, index) => (
                                        <div className='col-md-12 col-lg-6' key={index}>
                                            <div className='card_campaigns_main campaigns_list_card'>
                                                <div className="details-box">
                                                    <div className="price_heading_box">
                                                        <div className='details-box-first2'>
                                                            <button
                                                                className="btn_blue"
                                                                type='button'
                                                                onClick={() => navigate(`/joined-campaigns/${value?.campaign_id?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?.campaign_id?._id}`, { state: { mode: "joined" } })}
                                                            >
                                                                <div className='heading_campaings'> {value?.campaign_id?.campaign_name} </div>
                                                                <div className='share_next_page'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                                        <g id="Group_50385" data-name="Group 50385" transform="translate(-1512.25 -556.75)">
                                                                            <path id="Path_135412" data-name="Path 135412" d="M8.636,3.5a.5.5,0,0,0-.5-.5H1.5A1.5,1.5,0,0,0,0,4.5v10A1.5,1.5,0,0,0,1.5,16h10A1.5,1.5,0,0,0,13,14.5V7.864a.5.5,0,0,0-1,0V14.5a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5V4.5A.5.5,0,0,1,1.5,4H8.136A.5.5,0,0,0,8.636,3.5Z" transform="translate(1512.5 556.5)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                            <path id="Path_135413" data-name="Path 135413" d="M16,.5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0,0,1h3.793L6.146,9.146a.5.5,0,0,0,.708.708L15,1.707V5.5a.5.5,0,0,0,1,0Z" transform="translate(1512 557)" stroke="#000" strokeWidth="0.5" fillRule="evenodd" />
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </button>
                                                            <h1> <div className='price_icon'>$</div> <div className='price_number_text'><span>{value?.campaign_id?.no_of_credits ? value?.campaign_id?.no_of_credits : 0} </span> <span>Remaining Credit</span></div> </h1>
                                                        </div>
                                                        <div className="price_campaigns">
                                                            <h1> <div className='price_icon'>$</div><div className='price_number_text'> <span>{value?.campaign_id?.ppc_rate} </span> <span>PPC</span></div> </h1>
                                                            <div className='join_product_type_row campaigns_list'>
                                                                <button type='button' className='btn_blue joined-btn'>Joined</button>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    // delay={{ show: 250, hide: 400 }}
                                                                    overlay={(props) => (
                                                                        <Tooltip
                                                                            id="button-tooltip"
                                                                            {...props}
                                                                            style={{
                                                                                fontSize: 14,
                                                                                ...props.style,
                                                                            }}
                                                                        > Click the button to copy URL </Tooltip>
                                                                    )}
                                                                >
                                                                    <button type="button" className='btn_blue edit_delete' onClick={() => CopyLinkToClipboard(`https://www.glidescale.com/campaign-request?url=${value?.campaign_id?.marketing_assets}&link=${value?.short_link_id}`)}> <FaCopy /> </button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="details-box-first">
                                                        <div className="campaings_detail">
                                                            <h2> Product Types </h2>
                                                            <p> {value?.campaign_id?.product_type?.type_name} </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Tags </h2>
                                                            <p className='tags_line'>
                                                                {value?.campaign_id?.tags && value?.campaign_id?.tags?.length > 0 ?
                                                                    JSON.parse(value?.campaign_id?.tags)?.map((data, index) => (
                                                                        index < 6 ?
                                                                            <span> {data} </span>
                                                                            :
                                                                            index == 6 ? <span> +{JSON.parse(value?.campaign_id?.tags)?.length - 6} </span>
                                                                                :
                                                                                null
                                                                    ))
                                                                    : ""
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2> Target Link </h2>
                                                            <p>
                                                                <a href={value?.campaign_id?.target_link} className='text-ellipsis' target='_blank'> {value?.campaign_id?.target_link} </a>
                                                            </p>
                                                        </div>
                                                        <div className="campaings_detail">
                                                            <h2>Description</h2>
                                                            <p className='card_description_text'>{value?.campaign_id?.description?.length >= 90 ? `${value?.campaign_id?.description.substring(0, 90)}...` : value?.campaign_id?.description}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                }
                            </div>





                            {/* 
                            <div className='content_campaigns_user'>
                                {joinedCapmaignList && joinedCapmaignList.length > 0 ?
                                    joinedCapmaignList?.map((value, index) => (
                                        <div className='card_box_Campaigns' key={index}>
                                            <div className='campaigns_list'>
                                                <div>
                                                    <div className='campaigns_name'>{value?.campaign_id?.campaign_name}</div>
                                                    <div className='price_product'>
                                                        <div className='price_products'>
                                                            <BiDollar /> <span>{value?.campaign_id?.ppc_rate} Credits</span>
                                                        </div>
                                                        <div className='price_products'>
                                                            <MdProductionQuantityLimits /> <span>{value?.campaign_id?.product_type?.type_name}</span>
                                                        </div>
                                                    </div>
                                                    <p> {value?.campaign_id?.description?.length >= 90 ? `${value?.campaign_id?.description.substring(0, 90)}...` : value?.campaign_id?.description} </p>
                                                </div>
                                                <div className='my_campaigns_right_button'>
                                                    <button type='button' className='btn_blue joined-btn'>Joined</button>
                                                    <div className='three_dots_btn'>
                                                        <div className="drop-box drop_card_common">
                                                            <Dropdown drop={"start"}>
                                                                <Dropdown.Toggle className='btn_three_dots' variant="success" id={"dropdown-basic"}>
                                                                    <HiOutlineDotsVertical />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item
                                                                        className="nav-link"
                                                                        // as={Link}
                                                                        onClick={() => navigate(`/joined-campaigns/${value?.campaign_id?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?.campaign_id?._id}`, { state: { mode: "joined" } })}
                                                                    // to={``}
                                                                    >
                                                                        View
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <NoDataFound />
                                }
                            </div> */}
                        </Tab>

                        <Tab eventKey={4} title="Request Campaigns">
                            <div className='request_campaigns_list'>
                                <div className='content_campaigns_user table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                {/* <th className='text-center'>User</th> */}
                                                <th>Campaign Name</th>
                                                <th> Marketer Name</th>
                                                <th> Marketer Experience </th>
                                                <th>Product</th>
                                                {/* <th>Tags</th> */}
                                                <th>Target Link</th>
                                                <th>PPC Rate</th>
                                                <th> Status </th>
                                                <th className='text-end'> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestCapmaignList && requestCapmaignList.length > 0 ?
                                                requestCapmaignList.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        {/* <td>
                                                            <div className='profile_img_table'>
                                                                <img
                                                                    className='common_design_img'
                                                                    src={value?.user_id?.profile ? value?.user_id?.profile : "/images/dummy-image.jpg"}
                                                                    alt={value?.user_id?.full_name}
                                                                />
                                                            </div>
                                                        </td> */}
                                                        <td>{value?.campaign_id?.campaign_name}</td>
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='marketer_img me-3'>
                                                                    <img
                                                                        className='common_design_img'
                                                                        src={value?.user_id?.profile ? value?.user_id?.profile : "/images/dummy-image.jpg"}
                                                                        alt={value?.user_id?.full_name}
                                                                    />
                                                                </div>
                                                                <span> {value?.user_id?.full_name} </span>
                                                            </div>
                                                        </td>
                                                        <td> {value?.user_id?.experience ? value?.user_id?.experience : "-"} </td>
                                                        <td> {value?.campaign_id?.product_type?.type_name} </td>
                                                        {/* <td>
                                                            {value?.campaign_id?.tags ?
                                                                JSON.parse(value?.campaign_id?.tags)?.map((value) =>
                                                                    <span> {value} </span>
                                                                )
                                                                : ""
                                                            }
                                                        </td> */}
                                                        <td><a href={value?.campaign_id?.target_link} target='_blank'>Target Link</a></td>
                                                        <td>${value?.campaign_id?.ppc_rate}</td>
                                                        <td className='text-end fixed-side'>
                                                            <button
                                                                type='button'
                                                                className='table_btn reject'
                                                                onClick={() => handleRequestStatusUpdate(value._id, 2)}>
                                                                Reject
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='table_btn approve'
                                                                onClick={() => handleRequestStatusUpdate(value._id, 1)}>
                                                                Approve
                                                            </button>
                                                        </td>
                                                        <td className='text-end fixed-side'>
                                                            <button
                                                                onClick={() => navigate(`/request-campaigns/${value?.campaign_id?.campaign_name?.replace(/[\/\s]+/g, '_').toLowerCase()}/${value?.campaign_id?._id}`, { state: { mode: "request", id: value._id } })}
                                                                type='button'
                                                                className='common_btn_admin eye_btn_admin'
                                                            >
                                                                <MdOutlineRemoveRedEye />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan={8}> <NoDataFound /> </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
                                            
            <CommonModal
                show={ManualInviteShow}
                onHide={() => {
                    setManualInviteShow(false)
                    setCampaignsManulInviteMode({})
                }}
                title="Campaign Manual Invite"
                body={
                    <ManualInviteEmailModal
                        onClickButton1={() => {
                            setManualInviteShow(false)
                            setCampaignsManulInviteMode({})
                        }}
                        campaign_data={CampaignsManulInviteMode}
                        // setInviteLinkOptionData={setInviteLinkOptionData}
                    />
                }
            /> 
            
            <CommonModal
                show={CampaignsShow}
                onHide={() => {
                    setCampaignsShow(false)
                    setCampaignsEditMode({})
                }}
                title="New campaign (PPC)"
                body={
                    <CampaignsModal
                        onClickButton1={() => {
                            setCampaignsShow(false)
                            setCampaignsEditMode({})
                        }}
                        editMode={CampaignsEditMode}
                        setInviteLinkOptionData={setInviteLinkOptionData}
                    />
                }
            />

            <CommonModal
                show={Object.keys(inviteLinkOptionData)?.length > 1 ? true : false}
                onHide={() => { setInviteLinkOptionData("") }}
                modalClass="delete_Campaigns_modal"
                body={
                    <CampaignsInviteLinkModel
                        heading="Campaigns Invite Link"
                        onClickButton1={() => setInviteLinkOptionData("")}
                        btn1="Cancel"
                        data={inviteLinkOptionData}
                    />
                }
            />

            <CommonModal
                show={DeleteCampaignsShow}
                onHide={() => {
                    setDeleteCampaignsShow(false)
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        deleteIcon={true}
                        heading="Are you sure?"
                        description_text="Once you delete the campaign, it cannot be recovered."
                        onClickButton1={() => {
                            setDeleteCampaignsShow(false)
                            setDeleteCampaignsID("")
                        }}
                        onClickButton2={() => DeleteCampaings()}
                        loader={loader}
                        btn1="Cancel"
                        btn2="Delete"
                    />
                }
            />

            <CommonModal
                show={JoinCampaignsModalShow}
                onHide={() => {
                    setJoinCampaignsModalShow(false)
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        heading="Do you want to join the Campaign?"
                        onClickButton1={() => {
                            setJoinCampaignsModalShow(false)
                        }}
                        onClickButton2={() => JoinedCampaing()}
                        loader={loader}
                        btn1="No"
                        btn2="Yes"
                    />
                }
            />

            <CommonModal
                show={CompleteProfileShow}
                onHide={() => setCompleteProfileShow(false)}
                modalClass="delete_Campaigns_modal complete_profile_modal"
                body={
                    <CompleteProfile
                        hideModal={() => setCompleteProfileShow(false)}
                    />
                }
            />

            <CommonModal
                show={ActiveInactiveCampaignShow}
                onHide={() => {
                    setActiveInactiveCampaignShow(false)
                }}
                modalClass="delete_Campaigns_modal"
                body={
                    <DeleteCampaigns
                        deleteIcon={false}
                        heading={ActiveInactiveCampaignId?.status == 1 ? "Just a heads up!" : "Alert"}
                        description_text={ActiveInactiveCampaignId?.status == 1 ? "The campaign will be inactive in 24 hours, and the credits will be refunded automatically. We'll make sure to notify all the marketers involved!" : "Are you sure you want to reactive the campaign?"}
                        onClickButton1={() => {
                            setActiveInactiveCampaignShow(false)
                            setActiveInactiveCampaignId("")
                        }}
                        onClickButton2={() => handleStatusUpdate()}
                        loader={loader}
                        btn1="Cancel"
                        btn2={ActiveInactiveCampaignId?.status == 1 ? "Inactive" : "Activate"}
                    />
                }
            />
        </>
    )
}

export default Campaigns;