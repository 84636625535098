import { createSlice } from '@reduxjs/toolkit';
import api from '../Api';
import { authHeader } from '../authHeader';
import { ToastOverError, ToastOverSuccess } from '../../components/Common/ToastOver';
var CryptoJS = require("crypto-js");


const initialState = {
	registerManually: null,
	isAuthenticated: null,
	login: null,
	profile: null,
	forgotPass: null,
	resetPass: null,
	logout: null,
	AuthLoading: false,
	AuthError: null,
};

const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		fetchDataStart(state) {
			state.AuthLoading = true;
		},
		fetchSetCurrentUserSuccess(state, action) {
			state.AuthLoading = false;
			state.isAuthenticated = true;
			state.login = action.payload;
		},
		fetchLoginSuccess(state, action) {
			state.AuthLoading = false;
			state.isAuthenticated = true;
			state.login = action.payload;
		},
		fetchForgotPassSuccess(state, action) {
			state.AuthLoading = false;
			state.forgotPass = action.payload;
		},
		fetchResetPassSuccess(state, action) {
			state.AuthLoading = false;
			state.resetPass = action.payload;
		},
		fetchLogoutApiSuccess(state, action) {
			state.AuthLoading = false;
			state.logout = action.payload;
		},
		fetchViewProfileuccess(state, action) {
			state.AuthLoading = false;
			state.profile = action.payload;
		},
		fetchApiFailure(state, action) {
			state.AuthLoading = false;
			state.AuthError = action.payload;
		},
	},
});

export const {
	fetchDataStart,
	fetchApiFailure,
	fetchLoginSuccess,
	fetchViewProfileuccess,
	fetchForgotPassSuccess,
	fetchResetPassSuccess,
	fetchSetCurrentUserSuccess,
	fetchLogoutApiSuccess,
} = authSlice.actions;

export const setCurrentUser = (value) => async (dispatch) => {
	try {
		dispatch(fetchSetCurrentUserSuccess(value));
	} catch (error) {
	}
};

export const RegisterManuallyAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/register', value, {
			headers: authHeader(true),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const OTPVerificationAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/verify_otp', value, {
			headers: authHeader(true),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ResendOTP = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/resend_otp', value, {
			headers: authHeader(true),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const LoginAction = (value) => async (dispatch) => {
	try {
		dispatch(fetchDataStart());
		const data = await api?.api.post('app/users/login', value, {
			headers: authHeader(true),
		});
		// Encrypt
		if (data?.data?.user?.is_verify === 1) {
			var token = CryptoJS.AES.encrypt(JSON.stringify(data?.data), process.env.REACT_APP_JWT_SECRET).toString();
			localStorage.setItem("glidescale_security", token)
		}
		dispatch(fetchLoginSuccess(data?.data?.user));
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		dispatch(fetchApiFailure(error.message));
	}
};

export const SocialLoginAction = (value, callBack, dispatch) => async () => {
	try {
		const data = await api?.api.post('app/users/social_login', value, {
			headers: authHeader(true),
		});

		var token = CryptoJS.AES.encrypt(JSON.stringify(data?.data), process.env.REACT_APP_JWT_SECRET).toString();
		localStorage.setItem("glidescale_security", token)
		dispatch(fetchLoginSuccess(data?.data?.user));
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ForgotPassAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/forgot_password', value, {
			headers: authHeader(true),
		});
		ToastOverSuccess(data?.message)
		callBack(data)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ResetPassAction = (value, callBack) => async (dispatch) => {
	try {
		const data = await api?.api.post('app/users/reset_password', value, {
			headers: authHeader(true),
		});
		callBack(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ChangePassAction = (value, callBack) => async (dispatch) => {
	try {
		const data = await api?.api.post('app/users/change_password', value, {
			headers: authHeader(),
		});
		callBack(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ManualInviteEmailSentAction = (value, callBack) => async (dispatch) => {
	try {
		const data = await api?.api.post('app/users/mail_send', value, {
			headers: authHeader(),
		});
		callBack(data)
		ToastOverSuccess(data?.message)
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const CompleteProfileAction = (value, callBack) => async () => {
	try {
		const data = await api?.api.post('app/users/complete-profile', value, {
			headers: authHeader(),
		});
		callBack(data)
		ToastOverSuccess(data?.message)
		// let authToken = localStorage.getItem('glidescale_security');
		// var bytes = CryptoJS.AES.decrypt(authToken, process.env.REACT_APP_JWT_SECRET);
		// var authLocalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		// let storageData = { ...authLocalData}
		// let token = CryptoJS.AES.encrypt(JSON.stringify(storageData), process.env.REACT_APP_JWT_SECRET).toString();
		// localStorage.setItem("auth_gradstoc_security", token)
		// dispatch(fetchLoginSuccess({ ...storageData.user, profile: value.profile }));
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		callBack(error?.response?.data)
	}
};

export const ViewProfileAction = () => async (dispatch) => {
	try {
		dispatch(fetchDataStart());
		const data = await api?.api.get('app/users/profile-view', {
			headers: authHeader(),
		});
		dispatch(fetchViewProfileuccess(data?.data));
	} catch (error) {
		ToastOverError(error?.response?.data?.message)
		dispatch(fetchApiFailure(error.message));
	}
};

export const LogoutUser = () => async (dispatch) => {
	try {
		dispatch(fetchDataStart());
		const data = await api?.api.get('app/users/logout', {
			headers: authHeader(),
		});
		dispatch(fetchSetCurrentUserSuccess({}));
		localStorage.removeItem("glidescale_security");
		ToastOverSuccess(data?.message)
		dispatch(fetchLogoutApiSuccess(data));
	} catch (error) {
		dispatch(fetchApiFailure(error.message));
	}
};
export default authSlice.reducer;
